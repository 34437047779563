/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../scss/front.scss');

require('bootstrap/dist/js/bootstrap')
require('mdbootstrap/css/mdb.min.css')
require('mdbootstrap/js/modules/forms-free.min')
require('bootstrap/scss/bootstrap.scss')

require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.js');

import './components/alerts'
import './components/maps'

$('.carousel').carousel({
    interval: 6000,
    pause: false
})

