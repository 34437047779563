require('mapbox-gl/dist/mapbox-gl.css')
import mapboxgl from 'mapbox-gl/dist/mapbox-gl'

function loadMap(data){
    mapboxgl.accessToken = 'pk.eyJ1IjoidHV0b3hkOTAiLCJhIjoiY2s2bHVpcWpkMDg4MTNtb2hwNGxyMzd0dyJ9.lEQRzFBhV4LzTR-QYtFfAg';
    var map = new mapboxgl.Map({
        container: 'map-' + data.id ,
        center: [data.lon, data.lat],
        style: 'mapbox://styles/mapbox/streets-v11',
        zoom: 15
    });

    // create the popup
    var popup = new mapboxgl.Popup({ offset: 10 }).setHTML(
        '<p class="agency-name-pop pb-1 text-center">' + data.name + '</p>\n' +
        '<a class="pl-4 agency-coords-pop mb-1" href="https://www.google.fr/maps/place/' + data.address.split(' ').join('+') +'" class="" target="_blank"><i class="fas fa-lg fa-car"></i> Itinéraire</a>'
    );

    // create a DOM element for the marker
    var marker = new mapboxgl.Marker()
    .setLngLat([data.lon, data.lat])
    .setPopup(popup)
    .addTo(map);

    window.setInterval(function(){
        map.resize()
    }, 500);
}

var agencies = $(".agency-map")

agencies.each(function(index, agency) {
    loadMap(agency.dataset)
})



